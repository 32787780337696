import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  background-color: #fff;
  padding: 0 10px 20px 10px;
  flex: 1;
`

const Child = styled.div`
  display: grid;
  max-width: 840px;
  margin: 0 auto;
  grid-template-columns: 1fr;
  gap: 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr minmax(171px, 171px);
    gap: 99px;
  }
`
interface Props {
  children: React.ReactNode
}
const MediaPostContainer: React.FC<Props> = ({ children }) => (
  <Container>
    <Child>{children}</Child>
  </Container>
)

export default MediaPostContainer
