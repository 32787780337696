import { graphql, PageProps } from 'gatsby'
import React, { useMemo } from 'react'
import { MediaPostPageContext } from './MediaPostPage.context'
import MediaAbout from '../components/MediaAbout'
import MediaPostContainer from '../components/MediaPostContainer'
import MediaContent from '../components/MediaContent'
import { MediaPostQuery } from '../_generated/codegen/graphqlTypes'
import notEmpty from '../utils/notEmpty'
import { Unpacked } from '../utils/Unpacked'
import { Helmet } from 'react-helmet'

const useItem = (data: MediaPostQuery): IMediaPost => {
  return useMemo(() => {
    const items = data.strapi.mediaPosts?.filter(notEmpty) ?? []
    return items[0]
  }, [data])
}

const MediaPostPage: React.FC<
  PageProps<MediaPostQuery, MediaPostPageContext>
> = ({ data }) => {
  const item = useItem(data)

  return (
    <>
      <Helmet>
        <meta
          name="description"
          title={item.title}
          content={item.description}
        />
      </Helmet>

      <MediaPostContainer>
        <MediaContent item={item} />
        <MediaAbout reducedSocial />
      </MediaPostContainer>
    </>
  )
}

export type IMediaPost = NonNullable<
  Unpacked<NonNullable<MediaPostQuery['strapi']['mediaPosts']>>
>

export const query = graphql`
  query MediaPost($slug: String!) {
    strapi {
      mediaPosts(where: { slug: $slug }) {
        id
        type
        title
        subtitle
        description
        content
        cover {
          url
          name
          imageFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100, width: 600)
            }
          }
        }
        published_at
        slug
        author {
          id
          lastname
          firstname
          agence {
            id
            name
            email
          }
        }
      }
    }
  }
`

export default MediaPostPage
